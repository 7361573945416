define("zipbooks/components/drawers/gusto-integration-form", ["exports", "zipbooks/components/drawers/model-drawer"], function (_exports, _modelDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelDrawer.default.extend({
    store: Ember.inject.service(),
    title: 'Gusto Preferences',
    model: Ember.computed(function () {
      return this.store.queryRecord('gusto-integration', {});
    }),
    syncedTo: Ember.computed(function () {
      var date = this.model.get('syncedTo');
      if (!date) return ''; // Handle cases where the date is null or undefined

      var formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
      return formatter.format(new Date(date));
    }),
    minDate: Ember.computed(function () {
      var today = new Date();
      var lastYear = new Date();
      lastYear.setFullYear(today.getFullYear() - 1); // Add 1 day

      lastYear.setDate(lastYear.getDate() + 1); // Format to YYYY-MM-DD

      var year = lastYear.getFullYear();
      var month = String(lastYear.getMonth() + 1).padStart(2, '0'); // Months are 0-based

      var day = String(lastYear.getDate()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day); // YYYY-MM-DD
    })
  });

  _exports.default = _default;
});